import React from "react";
import { useLocation } from "../LocationProvider/LocationProvider";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export interface LocationHeroProps {
  className?: string;
}

export const LocationHero = (props: LocationHeroProps) => {
  const { className = "" } = props;
  const { image } = useLocation();
  return (
    <section className={`${className} w-full`}>
      <GatsbyImage className="w-full h-60 sm:h-auto" image={getImage(image as any)} />
    </section>
  );
};
