import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useMemo } from "react";
import { ContentRenderer } from "../../../Shared/ContentRenderer/ContentRenderer";
import { LocationModule } from "../../../Shared/LocationCards/model/location.model";
import { LocationSectionHeader } from "../../shared/LocationSectionHeader/LocationSectionHeader";

export interface LocationTextBlockProps
  extends LocationModule.CityTextImagesBlock {
  className?: string;
}

export const LocationTextBlock = (props: LocationTextBlockProps) => {
  const { className = "", text, title, gallery } = props;

  return (
    <div className={`${className} grid lg:grid-cols-2 gap-10`}>
      <div className="info">
        <LocationSectionHeader>{title}</LocationSectionHeader>
        <ContentRenderer source={text} />
      </div>
      <div className="images flex flex-col space-y-8">
        {gallery.map((im, index) => (
          <div key={index} className="flex w-full flex-1 ">
            <GatsbyImage
              key={index}
              className="w-full h-full shadow-lg"
              image={getImage(im.image)}
              alt={`${title} ${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
