import React, { createContext, useContext } from "react";
import { ReactNode } from "react-markdown";
import { LocationModule } from "../../Shared/LocationCards/model/location.model";

export interface LocationProviderProps {
  className?: string;
  children?: ReactNode[] | ReactNode;
  location: LocationModule.Location;
}

const locationContext = createContext<LocationModule.Location | null>(null);

export const useLocation = (): LocationModule.Location => {
  const location = useContext(locationContext);
  if (!location) throw new Error("Location not provided");
  return location;
};

export const LocationProvider = (props: LocationProviderProps) => {
  const { className = "", children, location } = props;
  return (
    <locationContext.Provider value={location}>
      {children}
    </locationContext.Provider>
  );
};
