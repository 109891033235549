import React, { useMemo } from "react";
import { Container } from "../../Shared/Container/Container";
import { useLocation } from "../LocationProvider/LocationProvider";

export interface LocationMapProps {
  className?: string;
}

const isClient = typeof window !== "undefined";

export const LocationMap = (props: LocationMapProps) => {
  const { className = "" } = props;
  const {
    GPS: { link },
  } = useLocation();

  return (
    <section className={`${className}`}>
      {isClient && (
        <iframe
          src={link}
          frameBorder="0"
          allowFullScreen
          aria-hidden="false"
          className="w-full h-96 shadow-lg"
          tabIndex={0}
        ></iframe>
      )}
    </section>
  );
};
