import React from "react";
import { useLocation } from "../LocationProvider/LocationProvider";
import { LocationTextBlock } from "./components/LocationTextBlock";

export interface LocationTextBlocksProps {
  className?: string;
}

export const LocationTextBlocks = (props: LocationTextBlocksProps) => {
  const { className = "" } = props;
  const { CityTextImagesBlock } = useLocation();
  console.log(CityTextImagesBlock);

  return (
    <article className={`${className} space-y-12`}>
      {CityTextImagesBlock.map((block) => (
        <LocationTextBlock key={block.title} {...block} />
      ))}
    </article>
  );
};
