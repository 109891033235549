import { graphql } from "gatsby";
import React from "react";
import { Hero } from "../../components/Home/Hero/Hero";
import { LocationCityParts } from "../../components/Location/LocationCityParts/LocationCityParts";
import { LocationCityToday } from "../../components/Location/LocationCityToday/LocationCityToday";
import { LocationHeader } from "../../components/Location/LocationHeader/LocationHeader";
import { LocationHero } from "../../components/Location/LocationHero/LocationHero";
import { LocationMap } from "../../components/Location/LocationMap/LocationMap";
import { LocationProvider } from "../../components/Location/LocationProvider/LocationProvider";
import { LocationShortHistory } from "../../components/Location/LocationShortHistory/LocationShortHistory";
import { LocationTextBlocks } from "../../components/Location/LocationTextBlocks/LocationTextBlocks";
import { Container } from "../../components/Shared/Container/Container";
import Layout from "../../components/Shared/Layout/Layout";
import { LocationCards } from "../../components/Shared/LocationCards/LocationCards";
import { LocationModule } from "../../components/Shared/LocationCards/model/location.model";
import { MessageFormSection } from "../../components/Shared/MessageFormSection/MessageFormSection";
import { SEO } from "../../components/Shared/SEO/SEO";

export interface LocationPageProps {
  className?: string;
}

const asLocation = (loc: any): LocationModule.Location => loc;

export const query = graphql`
  query getStrapiLocation($slug: String) {
    strapiLocation(slug: { eq: $slug }) {
      name
      CityTextImagesBlock {
        title
        text
        gallery {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      cityToday {
        title
        text
      }
      GPS {
        link
      }
      image {
        childImageSharp {
          gatsbyImageData
        }
        publicURL
      }
      partsOfTheCity {
        title
        text
        cityPart {
          name
        }
      }
      shortHistory {
        title
        text
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
      shortText
    }
  }
`;

export const LocationPage = (props: LocationPageProps) => {
  const {
    className = "",
    data: { strapiLocation },
  } = props;
  const location = asLocation(strapiLocation);
  return (
    <Layout className={`${className}`}>
      <LocationProvider location={location}>
        <SEO
          title={location.name}
          description={location.shortHistory.text}
          image={location.image.publicURL}
        />
        <LocationHero />
        <LocationHeader />
        <Container className="space-y-10 lg:mt-10">
          <LocationShortHistory />
          <div className="grid lg:grid-cols-2 gap-10">
            <LocationCityToday />
            <LocationCityParts />
          </div>
          <LocationMap />
          <LocationTextBlocks />
        </Container>
        <div className="form mt-16">
          <MessageFormSection />
        </div>
      </LocationProvider>
    </Layout>
  );
};

export default LocationPage;
