import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { ContentRenderer } from "../../Shared/ContentRenderer/ContentRenderer";
import { useLocations } from "../../Shared/LocationCards/hooks/useLocations";
import { useLocation } from "../LocationProvider/LocationProvider";
import { LocationSectionHeader } from "../shared/LocationSectionHeader/LocationSectionHeader";

export interface LocationShortHistoryProps {
  className?: string;
}

export const LocationShortHistory = (props: LocationShortHistoryProps) => {
  const { className = "" } = props;
  const {
    shortHistory: { text, title, image },
  } = useLocation();

  return (
    <article className={`${className} grid lg:grid-cols-2 gap-10`}>
      <div className="info">
        <LocationSectionHeader>{title}</LocationSectionHeader>
        <ContentRenderer source={text} />
      </div>
      <div className="image">
        <GatsbyImage className="h-full w-full" image={getImage(image as any)} alt={title} />
      </div>
    </article>
  );
};
