import { Heading } from "@chakra-ui/react";
import React from "react";
import { List } from "../../Shared/List/List";
import { useLocation } from "../LocationProvider/LocationProvider";
import { LocationSectionHeader } from "../shared/LocationSectionHeader/LocationSectionHeader";

export interface LocationCityPartsProps {
  className?: string;
}

export const LocationCityParts = (props: LocationCityPartsProps) => {
  const { className = "" } = props;
  const {
    partsOfTheCity: { title, text, cityPart },
  } = useLocation();
  return (
    <article className={`${className}`}>
      <LocationSectionHeader>{title}</LocationSectionHeader>
      <p className="">{text}</p>
      <List
        type="empty"
        className="mt-4"
        items={cityPart.map((part) => part.name)}
      />
    </article>
  );
};
