import React from "react";
import { ContentRenderer } from "../../Shared/ContentRenderer/ContentRenderer";
import { LocationHeader } from "../LocationHeader/LocationHeader";
import { useLocation } from "../LocationProvider/LocationProvider";
import { LocationSectionHeader } from "../shared/LocationSectionHeader/LocationSectionHeader";

export interface LocationCityTodayProps {
  className?: string;
}

export const LocationCityToday = (props: LocationCityTodayProps) => {
  const { className = "" } = props;
  const {
    cityToday: { title, text },
  } = useLocation();
  return (
    <article className={`${className}`}>
      <LocationSectionHeader>{title}</LocationSectionHeader>
      <ContentRenderer source={text} />
    </article>
  );
};
