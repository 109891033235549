import React from "react";
import { Container } from "../../Shared/Container/Container";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { useLocation } from "../LocationProvider/LocationProvider";

export interface LocationHeaderProps {
  className?: string;
}

export const LocationHeader = (props: LocationHeaderProps) => {
  const { className = "" } = props;
  const { name } = useLocation();
  return (
    <section className={`${className} py-8 lg:py-16`}>
      <Container>
        <SectionHeader
          className="text-center"
          subtitle="Realitné lokality"
          title={name}
        />
      </Container>
    </section>
  );
};
